import React from "react";
import { withStyles } from "@material-ui/core";
import {
  invoiceRepository,
  organisationRepository,
} from "./repositories/repositories.jsx";
import { formatDate } from "./formatDate.js";
import { CSVLink } from "react-csv";
import TableHeaderRow from './components/table.component.jsx';
const styles = (theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
});
var date = new Date();
var csvData = [
  [
    "Invoice Id",
    "Organisation Name",
    "User Email",
    "Course Name",
    "Subscription Date",
    "Price",
  ],
];
class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organisations: [],
      isError: false,
    };
  }
  componentDidMount() {
    organisationRepository.getAll(
      (res) => {
        this.setState({ organisations: res.data });
      },
      (error) => {
        alert("Failed to load organisations..");
      }
    );
  }

  render() {
    const ref =  React.createRef();
    const { classes } = this.props;
    return (
      <div>
        <table className="table table-hover">
        <TableHeaderRow titles={["Organisation Name", "Subscription Date", "Price", "View Details"]}/>
          <tbody>
               {
            this.state.organisations.map(
              (organisation,index) =>
              <InvoiceDataRow key={'invoice_' + index} index={index} orgName={organisation.name} orgId={organisation.id}/>
            )
            }
              );
          </tbody>
        </table>
      </div>
    );
  }
}
class InvoiceDataRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      organisations: [],
      startdate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substring(0, 10),
      enddate: date.toISOString().substring(0, 10),
      orgId: "",
      totalPrice:0,
      invoiceData:[]
    };
  }
  getInvoices = () => {
    invoiceRepository.fetchAllInvoices(
      formatDate(this.state.startdate),
      formatDate(this.state.enddate),
      this.props.orgId,
      (response) => {
        this.setState({ invoices: response.data });
      },
      (error) => alert("Failed to load invoices..")
    );
  }
  componentDidMount(){
    this.getInvoices();
  }
  componentDidUpdate(prevProps,prevState){
    if(this.state.invoices!==prevState.invoices){
      var price = 0
      this.state.invoices.map(invoice =>
        {
          price+= parseFloat(invoice.price);
          const data = [];
          data.push(invoice.id);
          data.push(invoice.organisation.name);
          data.push(invoice.userEmail);
          data.push(invoice.courseName);
          data.push(formatDate(invoice.subscriptionDate.substring(0,10)));
          data.push(invoice.price);
          csvData.push(data);
        })
      this.setState({totalPrice:price});
      this.setState({invoiceData:csvData}); 
      csvData = [
        [
          "Invoice Id",
          "Organisation Name",
          "User Email",
          "Course Name",
          "Subscription Date",
          "Price",
        ],
      ]; 
    }
    if (
      prevState.startdate !== this.state.startdate ||
      prevState.enddate !== this.state.enddate
    ) {
      this.getInvoices();
    }
  }

  handlestartdateChange = (e) => this.setState({ startdate: e.target.value });
  handleenddateChange = (e) => this.setState({ enddate: e.target.value });

  render() {
    return (<tr key={'invoicerow_' + this.props.index} >
      <td className="text-center">{this.props.orgName}</td>
      <td className="text-center">
                   <input
                          type="date"
                          defaultValue={this.state.startdate}
                          onChange={this.handlestartdateChange}
                        /> &nbsp; to &nbsp;{" "}
                 <input
                          type="date"
                          defaultValue={this.state.enddate}
                          onChange={this.handleenddateChange}
                        />
                </td>
      <td className="text-center">{this.state.totalPrice}</td>
      <td className="text-center">
      <CSVLink
                    data={this.state.invoiceData}
                    filename={"invoice.csv"}
                    className="btn btn-primary"
                    target="_blank"
                  >
                    Generate CSV
                  </CSVLink>
                  </td>
    </tr>);
  }
}
export default withStyles(styles)(Invoices);
