import React from 'react';
import TableHeaderRow from './components/table.component.jsx';
import { subscriptionRepository } from './repositories/repositories.jsx';
import styles from '../css/subscribedusers.css';

class SubscribedUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      courseId: props.match.params.id,
      users: [],
      emails: ""
    }
  }

  componentDidMount() {
    subscriptionRepository.allUsersFor(this.state.courseId,
      response => this.setState({ users: response.data }),
      error => alert("Failed to load users."));
  }

  fetchAll = () => {
    subscriptionRepository.allUsersFor(this.state.courseId,
      response => this.setState({ users: response.data }),
      error => alert("Failed to load users."));
  }

  handleUnsubscribe = (userId) => {
    subscriptionRepository.unsubscribeUserFromCourse(this.state.courseId, userId,
      response => {this.fetchAll()},
      error => alert("Failed to unsubscribe."));
  }

  handleSubmit = (emails) => {
    subscriptionRepository.subscribeAllByEmail(this.state.courseId, emails,
      response => this.fetchAll(),
      error => alert("Something went wrong while adding the users to the course.")
    );
  }



  render() {
    return (
      <div>
         
        <table className="table table-hover">
          <TableHeaderRow titles={["Id", "Name", "Email","Subscription Date","Unsubscribe"]} />
          <tbody>
            {this.state.users.map((user, index) => <UserDataRow key={'users_' + index} index={index}
              user={user} handleUnsubscribe={this.handleUnsubscribe}/>)}
          </tbody>
        </table>
        <AddUsers handleSubmit={this.handleSubmit} />
      </div>
    );
  }
}

class UserDataRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (<tr key={'userrow_' + this.props.index} >
      <td className="text-center">{this.props.user.id}</td>
      <td className="text-center">{this.props.user.name}</td>
      <td className="text-center">{this.props.user.email}</td>
      <td className="text-center">{this.props.user.subscriptionDate}</td>
      <td className="text-center">
        <button className="btn btn-warning" onClick={() => this.props.handleUnsubscribe(parseInt(this.props.user.id))}>
          <i className="fa fa-user-times" aria-hidden="true"></i>
        </button>
      </td>
    </tr>);
  }
}

class AddUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: ""
    }
  }

  handleEmailChange = (element) => this.setState({ emails: element.target.value });
  handleSubmit = () => {
    this.setState({ emails: "" });
    this.props.handleSubmit(this.state.emails);
  }
  render() {
    return (
      <div className="add-users-container">
        <div className="add-users">
          <textarea className="email-csv" type="text" placeholder="Comma separated email ids of users to subscribe."
            value={this.state.emails} onChange={this.handleEmailChange} />
          <button className="btn-success fa fa-plus email-csv-submit" onClick={this.handleSubmit} />

        </div>
      </div>);
  }
}

export default SubscribedUsers;