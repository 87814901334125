import React from 'react';
import UserProfile from './jsx/UserProfile.jsx';
import ManageCourse from './jsx/ManageCourse.jsx';
import OrganistionPane from './jsx/OrganisationPane.jsx';
import Courses from './jsx/Courses.jsx';
import App from './jsx/App.jsx';
import Users from './jsx/Users.jsx';
import SubscribedUsers from './jsx/pages.subscribedusers.jsx';
import LessonDetail from './jsx/LessonDetail.jsx';
import { BrowserRouter as Router, Route, Link, Switch,Redirect } from "react-router-dom";
import './css/bootstrap.min.css'; 
import './css/mdb.min.css'; 
import api from './jsx/api.jsx';
import url from './jsx/UrlMapping.jsx';
import Email from './jsx/Email'
import SubscriberFilter from './jsx/SubscriptionFilter';
import Invoices from './jsx/Invoices';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest}) => (
	<Route {...rest} render={(props) => (
	  isAuthenticated ? <Component {...props} />
		: <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
	)} />
  )


class Main extends React.Component{
	state = {
		isAuthenticated : undefined,
		loginLink : '',
		error: undefined
	}

	componentDidMount(){
		api.get(url + "/loggedin")
      .then(res => {
		this.setState({isAuthenticated: res.data.loggedin, loginLink: res.data.links[0].href})
      }).catch(function (error) {
		this.setState({error:"Something went wrong please try again later"})
      });
	}
	
	render(){
		if(this.state.isAuthenticated === undefined) return null
		if(this.state.error) return <div>{this.state.error}</div>
		return(	
		
			<Router>
				<div>
					<Switch>
						<Route path="/login" render={() => {window.location.assign(`http://${this.state.loginLink}?continue=${window.location.host}`)}} />
						<PrivateRoute path="/" exact isAuthenticated={this.state.isAuthenticated} component={App} />
						<PrivateRoute path="/users" exact isAuthenticated={this.state.isAuthenticated} component={Users} />
						<PrivateRoute path="/users/:id" exact isAuthenticated={this.state.isAuthenticated} component={UserProfile} />
						<PrivateRoute path="/organisations" exact isAuthenticated={this.state.isAuthenticated} component={OrganistionPane} />
						<PrivateRoute path="/email" exact isAuthenticated={this.state.isAuthenticated} component={Email} />
						<PrivateRoute path="/courses"  exact isAuthenticated={this.state.isAuthenticated} component={Courses}/>
						<PrivateRoute path="/courses/:id" exact isAuthenticated={this.state.isAuthenticated} component={ManageCourse} />	
						<PrivateRoute path="/courses/:id/users" exact isAuthenticated={this.state.isAuthenticated} component={SubscribedUsers} />	
						<PrivateRoute path="/lessons/:id" exact isAuthenticated={this.state.isAuthenticated} component={LessonDetail} />
						<PrivateRoute path="/subHistory" exact isAuthenticated={this.state.isAuthenticated} component={SubscriberFilter} />
						<PrivateRoute path="/invoices" exact isAuthenticated={this.state.isAuthenticated} component={Invoices} />						
					</Switch>
				</div>
			</Router>
		)
	}
}

export default Main;
