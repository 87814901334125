import React, { Component } from 'react';
import loginRepository from '../repositories/repositories.login.jsx';
import { userRepository } from '../repositories/repositories.jsx';
import img from '../../img/interleaplogo.png';
import styles from '../../css/pageHeader.css';
import 'react-tabs/style/react-tabs.css';

class AdminHeader extends Component {

  constructor(props) {
    super(props)
    this.state = {
      user: {
        id: 0,
        name: '',
        email: '',
        organisationId: 0,
        courses: []
      }
    }
  }

  componentDidMount() {
    userRepository.getCurrentUser(
      res => {
        const user = res.data;
        this.setState({ user });
      },
      (error) => {
        if (error.response && (error.response.status > 400 && error.response.status <= 500))
          window.location = "/login";
        else
          alert("Unable to load user information for the header. Try again later");
      })

  }

  logout = () => {
    loginRepository.logout(
      res => { if (res.status == 200) { window.location = "/login"; } },
      (error) => { alert("Could not logout") })
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar warning-color-dark lighten-5">
        <div >
          <img src={img} className="logo" />
        </div>
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href="/users">Users</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/courses">Courses</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/organisations">Organisations</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/email">Email</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/subHistory">Subscriptions</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/invoices">Invoices</a>
          </li>
        </ul>
        <div className="userDetailsandlogout">
          <div className="userDetailsAdmin">
            {this.state.user.name}<br />
            {this.state.user.email}
          </div>
          <div className="logout">
            <button type="button" className="btn btn-success" onClick={this.logout}>Logout</button>
          </div>
        </div>
      </nav>
    )
  }
}

export default AdminHeader;