import Delete from "@material-ui/icons/Delete";
import React, { useEffect } from "react";
import Save from "@material-ui/icons/Save";
import Reorder from "@material-ui/icons/ArrowUpward";
import { Button } from "@material-ui/core";
import Dropdown from "./Dropdown.jsx";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Option from "./Option";
import Add from "@material-ui/icons/AddCircle";
import { lectureRepository } from "./repositories/repositories.jsx";

const useStyles = makeStyles((theme) => ({
  content: {},
  lectureBody: {
    padding: "2em",
    margin: "0.2em",
    width: "60%",
    "&:hover": {
      boxShadow: "3px 3px 13px #888",
    },
  },
  lectureName: {
    marginBottom: "0.85%",
    display: "flex",
    justifyContent: "baseline",
  },
  videoid: {
    marginTop: "0.55%",
  },
  actionsContainer: {
    display: "flex",
  },
  lectureNameDropdownContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: "2em",
  },
  actionsAndIdContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  lectureId: {
    marginRight: "3%",
  },
  textarea: {
    backgroundColor: "transparent",
    border: "none",
    color: "#555",
    boxSizing: "border-box",
    borderBottom: `1px solid #ddd0`,
    fontSize: "18px",
    left: "0%",
    padding: "0px 0px",
    position: "relative",
    resize: "none",
    width: "40vw",
    overflow: "hidden",
    marginRight: "1em",
    "&:focus": {
      outline: "none",
      color: "#111",
    },
    "&:hover": {
      borderBottom: `2px solid #666`,
    },
  },
  Highlight: {
    backgroundColor: "rgba(68, 176, 251,0.6)",
  },
  Title: {
    fontWeight: "700",
    fontSize: "24px",
  },
  OptionsContainer: {
    display: "flex",
    marginTop: "2.5em",
  },
  AddOptionsButton: {
    backgroundColor: "red",
    padding: "0 auto",
  },
}));

function LectureCard({
  lecture,
  handleShiftUp,
  handleDelete,
  addCard,
  focused,
  getLesson,
  lessonId,
}) {
  const [content, setContent] = React.useState("");
  const [name, setName] = React.useState("");
  const [videoId, setVideoId] = React.useState("");
  const [lectureType, setLectureType] = React.useState("");
  const [moved, setMoved] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const myRef1 = React.useRef();
  const myRef2 = React.useRef();

  function resizeTextarea(textarea) {
    textarea.style.height = "inherit";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  function handleChangeName(e) {
    setName(e.target.value);
  }

  const handleChangeContent = (e) => {
    setContent(e.target.value);
  };

  const handleChangeVideoId = (e) => {
    setVideoId(e.target.value);
  };

  const handleChangeOptions = (e, index) => {
    let optionsUpdated = [...options];
    optionsUpdated[index].option = e.target.value;
    setOptions(optionsUpdated);
  };

  const handleChangeCheckbox = (index) => {
    let optionsUpdated = [...options];
    optionsUpdated[index].correct = !optionsUpdated[index].correct;
    setOptions(optionsUpdated);
  };

  const handleDeleteOptions = (index) => {
    let optionsUpdated = [...options];
    optionsUpdated = optionsUpdated.filter(function (option, pos) {
      return pos != index;
    });
    setOptions(optionsUpdated);
  };

  useEffect(() => {
    if (!addCard) {
      setContent(lecture.content || "");
      if (lecture.type === "MCQ") setContent(lecture.question || "");
      setName(lecture.name || "");
      setVideoId(lecture.videoId || "");
      setLectureType(lecture.type || "");
      setOptions(lecture.options);
      setMoved(focused);
    }
  }, [lecture.id, moved]);

  function resizeAllTextareas() {
    resizeTextarea(myRef1.current);
    resizeTextarea(myRef2.current);
  }

  useEffect(() => {
    resizeAllTextareas();
  }, [lecture.id, content, videoId, name]);


  function resetCard() {
    setContent("");
    setOptions([{ option: "", correct: false, key: new Date().getTime() }]);
    setName("");
    setVideoId("");
    setLectureType(lecture.type || "DOCUMENT");
  }
  const handleSave = (newLecture) => {
    return lectureRepository.save(
      newLecture,
      (res) => {
        getLesson();
        resetCard();
      },
      (error) => {
        if (
          (error.response && error.response.status === 409) ||
          error.response.status === 400
        ) {
          alert(error.response.data.message);
        } else {
          alert("Could not add lecture, please try again later.");
        }
      }
    );
  };

  const handleLectureEdit = (lecture) => {
    lectureRepository.update(
      lecture,
      (response) => getLesson(),
      (error) => {
        if (
          error.response &&
          (error.response.data.status === 409 || error.response.status === 400)
        ) {
          alert(error.response.data.message);
        } else {
          alert("Could not update lecture. Please try again later.");
        }
      }
    );
  };

  const mapAttributesToLecture = (
    id,
    name,
    content,
    videoId,
    type,
    optionsArray
  ) => {
    let options = [];
    if (optionsArray) {
      options = optionsArray.filter(function (option) {
        return typeof option.option === "string" && option.option.length > 0;
      });
      options = options.map((option) => {
        return { option: option.option, correct: option.correct };
      });
    }
    return { id, name, lessonId: lessonId, videoId, content, type, options };
  };

  useEffect(() => {
    if (addCard) {
      resetCard();
    }
  }, []);

  const classes = useStyles();
  const movedClass = moved ? classes.Highlight : null;
  const lectureTypeComponent = addCard ? (
    <Dropdown value={lectureType} 
    setValue={setLectureType} 
    items={['DOCUMENT', 'QUESTION', 'VIDEO', 'MCQ', 'CODE_EVALUATION']}
    />
  ) : (
    <div>{lectureType}</div>
  );

  return (
    <Paper className={[classes.lectureBody, movedClass].join(" ")}>
      <div className={classes.lectureNameDropdownContainer}>
        <div className={classes.lectureName}>
          <textarea
            ref={myRef1}
            className={[classes.textarea, classes.Title].join(" ")}
            rows="1"
            onChange={handleChangeName}
            placeholder="Lecture name"
            value={name}
          ></textarea>
        </div>
        {lectureTypeComponent}
      </div>

      <div>
        <textarea
          ref={myRef2}
          className={classes.textarea}
          rows="1"
          onChange={handleChangeContent}
          placeholder="Lecture content"
          value={content}
        ></textarea>
      </div>
      {lectureType === "MCQ" &&
        options.map((option, index) => (
          <div
            className={classes.OptionsContainer}
            key={option.id || option.key}
          >
            <Option
              index={index}
              handleChangeOptions={handleChangeOptions}
              handleDeleteOptions={handleDeleteOptions}
              handleChangeCheckbox={handleChangeCheckbox}
              resizeTextarea={resizeTextarea}
              option={option.option}
              correct={option.correct}
            />

            {index + 1 === options.length ? (
              <Button
                onClick={() => {
                  setOptions([
                    ...options,
                    { option: "", correct: false, key: new Date().getTime() },
                  ]);
                }}
              >
                <Add />
              </Button>
            ) : null}
          </div>
        ))}

      {lectureType === "MCQ" && options.length === 0 && (
        <Button
          onClick={() => {
            setOptions([
              ...options,
              { option: "", correct: false, key: new Date().getTime() },
            ]);
          }}
        >
          <Add />
        </Button>
      )}

      <br />
      <hr />
      <div className={classes.actionsAndIdContainer}>
        <div className={classes.videoid}>
          <input
            className={classes.textarea}
            rows="1"
            onChange={handleChangeVideoId}
            placeholder="Lecture videoId"
            value={videoId}
          ></input>
        </div>
        <div className={classes.actionsContainer}>
          {!addCard && (
            <Button onClick={() => handleDelete(parseInt(lecture.id))}>
              <Delete />
            </Button>
          )}
          <Button
            onClick={() => {
              if (addCard) {
                handleSave(
                  mapAttributesToLecture(
                    lecture.id,
                    name,
                    content,
                    videoId,
                    lectureType,
                    options
                  )
                );
              } else {
                handleLectureEdit(
                  mapAttributesToLecture(
                    lecture.id,
                    name,
                    content,
                    videoId,
                    lectureType,
                    options
                  )
                );
              }
            }}
          >
            <Save />
          </Button>
          {!addCard && (
            <Button
              onClick={() => {
                handleShiftUp(parseInt(lecture.id));
                setMoved(true);
              }}
            >
              <Reorder />
            </Button>
          )}
        </div>
      </div>
    </Paper>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.lecture === nextProps.lecture;
}

export default React.memo(LectureCard, areEqual);
