import React from 'react';
import { organisationRepository } from './repositories/repositories.jsx';
import TableHeaderRow from './components/table.component.jsx';


class OrganisationPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organisations: [],
      id: "",
      isError: false,
      accountLimit: '',
      orgName: '',
      updatedAccountLimit: '',
      updatedorgName: '',
      readOnlyMode: []
    };

  }

  //To Retrieve all the organisations from spring boot api through axios
  componentDidMount() {
    this.fetchAll();
  };

  fetchAll = () => {
    organisationRepository.getAll(
      res => {
        var readOnlyMode = this.state.readOnlyMode;
        this.setState({ organisations: res.data });
        let len = this.state.organisations.length;
        for (let i = 0; i < len; i++) {
          readOnlyMode[i] = true;
        }
        this.setState({ isError: false, readOnlyMode });
      },
      (error) => {
        this.setState({ isError: true });
      })
  }

  handleChangeName = (name) => {
    this.setState({ orgName: name });

  }
  handleChangeAccountLimit = (accountLimit) => {
    this.setState({ accountLimit: accountLimit });

  }
  handleUpdatedAccountLimit = (e) => {
    this.setState({ updatedAccountLimit: e.target.value });
  }
  handleUpdatedName = (e) => {
    this.setState({ updatedorgName: e.target.value });
  }


  handleSubmit = (e) => {
    e.preventDefault();

    organisationRepository.save({ name: this.state.orgName, accountLimit: this.state.accountLimit },
      res => {
        this.setState({orgName: "", accountLimit: "" });
        this.fetchAll();
      },
      (error) => {
        if (error.response && error.response.status === 409 || error.response.status === 400) { alert(error.response.data.message) }
        else { alert("Could not add user, please try again later."); }
      })
  };
  handleDelete = (i) => {
    organisationRepository.delete(i,
      res => this.fetchAll(),
      (error) => {
        if (error.response && error.response.status === 409) {
          alert(error.response.data.message)
        }
        else {
          alert("Could not delete organisation, please try again later.");
        }
      })
  };

  handleEdit = (i, orgName, accountLimit) => {
    var readOnlyMode = this.state.readOnlyMode;
    readOnlyMode[i] = false;
    this.setState({ updatedorgName: orgName, readOnlyMode, updatedAccountLimit: accountLimit });

  }
  handleSave = (i, id, orgName, accountLimit) => {
    var readOnlyMode = this.state.readOnlyMode;
    readOnlyMode[i] = true;
    this.setState({ readOnlyMode })

    organisationRepository.update({ id: id, name: this.state.updatedorgName, accountLimit: this.state.updatedAccountLimit },
      res => {
        this.fetchAll();
      },
      (error) => {
        if (error.response.status === 409 || error.response.status === 400) {
          alert(error.response.data.message)
          this.setState({ updatedAccountLimit: accountLimit, updatedorgName: orgName });
        }
        else {
          alert("Could not Edit organisations, please try again later.");
        }

      })
  };



  renderButton(i, id, orgName, accountLimit) {
    if (this.state.readOnlyMode[i]) {
      return (
        <button className="btn btn-info" onClick={() => this.handleEdit(i, orgName, accountLimit)}>
          <i className="fa fa-edit" aria-hidden="true"></i>
        </button>
      );
    } else {
      return (

        <button className="btn btn-success" onClick={() => this.handleSave(i, id, orgName, accountLimit)}>
          <i className="fa fa-check-square" aria-hidden="true"></i>
        </button>
      );
    }
  }

  render() {

    if (this.state.isError)
      return (
        <div>
          <h3 className="text-center">Could not fetch organisations, please try again later.</h3>
        </div>);

    else {
      return (
        <div>
          <table className="table table-hover">
            <TableHeaderRow titles={["Id", "Name", "Account Limit", "Action"]} />
            <tbody>

              {this.state.organisations.map((organisation, i) =>
                (<tr key={'organisations_' + i}>
                  <td className="text-center">{organisation.id}</td>
                  <td className="text-center"><textarea className="textarea" rows="1" cols="30" readOnly={this.state.readOnlyMode[i]} onChange={this.handleUpdatedName} defaultValue={organisation.name} /></td>
                  <td className="text-center"><textarea className="textarea" rows="1" cols="30" readOnly={this.state.readOnlyMode[i]} onChange={this.handleUpdatedAccountLimit} defaultValue={organisation.accountLimit} /></td>

                  <td>{this.renderButton(i, parseInt(organisation.id), organisation.name, organisation.accountLimit)}
                    <button className="btn btn-danger" onClick={() => this.handleDelete(parseInt(organisation.id))}>
                      <i className="fa fa-close" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>)
              )}
              <AddOrganisationRow

                orgName={this.state.orgName}
                accountLimit={this.state.accountLimit}
                handleSubmit={this.handleSubmit}
                handleChangeName={this.handleChangeName}
                handleChangeAccountLimit={this.handleChangeAccountLimit} />

            </tbody>

          </table>

        </div>
      );
    }
  }
}
class AddOrganisationRow extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeAccountLimit = this.handleChangeAccountLimit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChangeName(e) { this.props.handleChangeName(e.target.value); }
  handleChangeAccountLimit(e) { this.props.handleChangeAccountLimit(e.target.value); }
  handleSubmit(e) { this.props.handleSubmit(e); }
  render() {
    return (
      <tr>
        <td></td>
        <td className="text-center"><input type="text" placeholder="Name" name="orgName" value={this.props.orgName} onChange={this.handleChangeName} /></td>
        <td className="text-center"><input type="text" placeholder="Account Limit" name="accountLimit" value={this.props.accountLimit} onChange={this.handleChangeAccountLimit} /></td>

        <td className="text-center">
          <button className="btn-success" onClick={this.handleSubmit}>
            <i className="fa fa-plus" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    );
  }
}
export default OrganisationPane;