import React from "react";
import "react-tabs/style/react-tabs.css";
import { userRepository,organisationRepository } from "./repositories/repositories.jsx";
import "../css/bootstrap.css";
import "../css/bootstrap-theme.css";
import { withStyles } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {formatDate} from './formatDate.js'
import Grid from '@material-ui/core/Grid';
const styles = (theme) => ({
  userRow: {
    height: "91.5vh",
  },
  addUserRow: {
    backgroundColor: "#4446",
  },
  typography: {
    padding: theme.spacing(2),
  },
});
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      organisations:[],
      userName: "",
      existingUserName: "",
      userEmail: "",
      existingUserEmail: "",
      passwrd: "",
      role: "",
      org: "",
      startdate: new Date(2018,1,1).toISOString().substring(0, 10),
      enddate: new Date().toISOString().substring(0, 10),
      orgId: "",
      orgfilter: false,
      datefilter: false,
      isError: false,
      readOnly: [],
    };
  }
  handleChangeName = (name) => this.setState({ userName: name });
  handleChangeEmail = (email) => this.setState({ userEmail: email });
  handleChangePassword = (pass) => this.setState({ passwrd: pass });
  handleChangeRole = (role) => this.setState({ role: role });
  handleChangeOrganisation = (org) => this.setState({ org: org });
  handlestartdateChange = (e) => this.setState({ startdate: e.target.value });
  handleenddateChange = (e) => this.setState({ enddate: e.target.value });
  handleorgIdChange = (e) => {
    this.state.organisations.filter(org => org.name === e.target.value).map(org => {this.setState({orgId:org.id})})
  }

  resetOrgState = () =>{
      this.setState({orgId:""});
  }
  resetDateState = () =>{
    this.setState({startdate:new Date(2018,1,1).toISOString().substring(0, 10), enddate:new Date().toISOString().substring(0, 10)})
  }
  componentDidMount() {
    userRepository.getAll(
      (response) => {
        this.setState({ isError: false, users: response.data });
        var readOnly = this.state.readOnly;
        for (let i = 0; i < this.state.users.length; i++) {
          readOnly[i] = true;
        }
        this.setState({ readOnly });
      },
      (error) => {
        this.setState({ isError: true });
      }
    );
    organisationRepository.getAll(res => {this.setState({ organisations: res.data })},
    (error) => {
      console.log("Failed to load organisations..")
    })
  }
  componentDidUpdate(prevProps,prevState) {
    if (
      prevState.startdate !== this.state.startdate ||
      prevState.enddate !== this.state.enddate ||
      prevState.orgId != this.state.orgId
    ) {
      userRepository.allUsers(
        formatDate(this.state.startdate),
        formatDate(this.state.enddate),
        this.state.orgId,
        (response) => {
          this.setState({ isError: false, users: response.data });
        },
        (error) => console.log("Failed to load users..")
      );
    }
  }

  handleOrgFilterArrowClick = () =>
    this.setState({ orgfilter: !this.state.orgfilter });
  handleDateFilterArrowClick = () =>
    this.setState({ datefilter: !this.state.datefilter });
  handleEdit = (index) => {
    var readOnly = this.state.readOnly;
    readOnly[index] = false;
    this.setState({ readOnly });
  };

  handleSave = (index, id, name, email, organisationId, role) => {
    var readOnly = this.state.readOnly;
    readOnly[index] = true;
    this.setState({ readOnly });

    userRepository.update(
      {
        id: id,
        name: name,
        email: email,
        organisationId: organisationId,
        role: role,
      },

      (res) => {
        this.setState({ users: res.data });
      },
      (error) => {
        if (error.response.status === 409 || error.response.status === 400) {
          alert(error.response.data.message);
        } else {
          alert("Could not Edit organisations, please try again later.");
        }
      }
    );
  };

  renderButton = (index, id, name, email, organisationId, role) => {
    if (this.state.readOnly[index]) {
      return (
        <button className="btn btn-info" onClick={() => this.handleEdit(index)}>
          <i className="fa fa-edit" aria-hidden="true"></i>
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-success"
          onClick={() =>
            this.handleSave(index, id, name, email, organisationId, role)
          }
        >
          <i className="fa fa-check-square" aria-hidden="true"></i>
        </button>
      );
    }
  };

  handleSubmit = (e) => {
    //to prevent default action happen
    e.preventDefault();
    userRepository.save(
      {
        name: this.state.userName,
        email: this.state.userEmail,
        password: this.state.passwrd,
        organisationId: this.state.org,
        role: this.state.role,
      },
      (res) =>
        this.setState({
          users: res.data,
          userName: "",
          userEmail: "",
          passwrd: "",
          org: "",
          role: "",
        }),
      (error) => {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500 &&
          error.response.data
        ) {
          alert(error.response.data.message);
        } else {
          alert("Could not add users, please try again later.");
        }
      }
    );
  };
  handleDelete = (i) => {
    if (confirm("Are you sure you want to delete user?"))
      userRepository.deleteUser(
        i,
        (res) =>
          this.setState({
            users: res.data,
            userName: "",
            userEmail: "",
            passwrd: "",
            org: "",
            role: "",
          }),
        (error) => {
          if (error.response.status === 409) {
            alert(error.response.data);
          }
        }
      );
  };
  render() {
    const { classes } = this.props;
    if (this.state.isError)
      return (
        <div>
          <h3 className="text-center">
            Could not fetch users, please try again later.
          </h3>
        </div>
      );
    else {
      return (
        <div className={classes.userRow}>
          <table className="table table-hover">
            <thead>
              <tr className="bg-warning">
                <th className="text-center">
                  <u>
                    <h5>User&nbsp;Id</h5>
                  </u>
                </th>
                <th className="text-center">
                  <u>
                    <h5>User Name</h5>
                  </u>
                </th>
                <th className="text-center">
                  <u>
                    <h5>User Email</h5>
                  </u>
                </th>
                <th className="text-center">
                  <u>
                    <h5>Password</h5>
                  </u>
                </th>
                <th className="text-center">
                  <u>
                    <h5>
                    <Grid  style={{ display: "flex" }}>
                          Organisation&nbsp;Id
                        <ArrowDropDownIcon
                          aria-describedby="orgFilter"
                          variant="contained"
                          onClick={this.handleOrgFilterArrowClick}
                        />
                      </Grid>
                        <Popover
                          open={this.state.orgfilter}
                          anchorReference="anchorPosition"
                          anchorPosition={{ top: 80, left: 720 }}
                          id="orgFilter"
                          onClose={this.handleOrgFilterArrowClick}
                        >
                          <Typography className={classes.typography}>
                            <input
                              type="text"
                              onChange={this.handleorgIdChange}
                            />
                            <br/><br />
                            {
                              this.state.organisations.map(
                                (org,index) =>
                               <div key={index}>
                                <input type="radio" id={org.name} value={org.name} onClick={this.handleorgIdChange} checked={this.state.orgId==org.id}/>&nbsp;&nbsp;
                                <label for={org.name}>{org.name}</label><br/>
                                </div>
                              )
                            }
                            <br />
                            <center><button onClick={this.resetOrgState}>Reset</button></center>
                          </Typography>
                        </Popover>
                    </h5>
                  </u>
                </th>
                <th className="text-center">
                  <u>
                    <h5>Role</h5>
                  </u>
                </th>
                <th className="text-center">
                  <u>
                    <h5>
                    <Grid  style={{ display: "flex" }}>
                      Created&nbsp;At
                      <ArrowDropDownIcon
                        aria-describedby="dateFilter"
                        variant="contained"
                        onClick={this.handleDateFilterArrowClick}
                      />
                      </Grid>
                      <Popover
                        open={this.state.datefilter}
                        id="dateFilter"
                        anchorReference="anchorPosition"
                          anchorPosition={{ top: 80, left: 980 }}
                        onClose={this.handleDateFilterArrowClick}
                      >
                        <Typography className={classes.typography}>
                          Start Date:
                          <input
                            type="date"
                            onChange={this.handlestartdateChange}
                            value={this.state.startdate}
                          />{" "}<br /><br />
                          End Date  :{" "}
                          <input
                            type="date"
                            onChange={this.handleenddateChange}
                            value={this.state.enddate}
                          />
                          <br /><br />
                          <center><button onClick={this.resetDateState}>Reset</button></center>
                        </Typography>
                      </Popover>
                    </h5>
                  </u>
                </th>
                <th className="text-center">
                  <u>
                    <h4>Action</h4>
                  </u>
                </th>
              </tr>
            </thead>
            <tbody>
              {
              this.state.users.map((user, i) => (
                <UserRow
                  key={user.id}
                  index={i}
                  user={user}
                  handleDelete={this.handleDelete}
                  readOnly={this.state.readOnly}
                  renderButton={this.renderButton}
                  handleChangeEmail={this.handleChangeEmail}
                  handleChangeName={this.handleChangeName}
                />
              ))
              }
              <AddUserRow
                userName={this.state.userName}
                userEmail={this.state.userEmail}
                passwrd={this.state.passwrd}
                org={this.state.org}
                role={this.state.role}
                handleSubmit={this.handleSubmit}
                handleChangeName={this.handleChangeName}
                handleChangeEmail={this.handleChangeEmail}
                handleChangePassword={this.handleChangePassword}
                handleChangeRole={this.handleChangeRole}
                handleChangeOrganisation={this.handleChangeOrganisation}
                addUserRowClass={classes.addUserRow}
              />
            </tbody>
          </table>
        </div>
      );
    }
  }
}
class AddUserRow extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChangeName = (e) => this.props.handleChangeName(e.target.value);
  handleChangeEmail = (e) => this.props.handleChangeEmail(e.target.value);
  handleChangePassword = (e) => this.props.handleChangePassword(e.target.value);
  handleChangeRole = (e) => this.props.handleChangeRole(e.target.value);
  handleChangeOrganisation = (e) =>
    this.props.handleChangeOrganisation(e.target.value);
  handleSubmit = (e) => this.props.handleSubmit(e);
  render() {
    return (
      <tr
        className={[
          "fixed-bottom",
          "w-100",
          "table-borderless",
          this.props.addUserRowClass,
        ].join(" ")}
      >
        <td className="w-90"></td>
        <td className="text-center mr-1" width="400">
          <input
            type="text"
            className="w-100"
            placeholder="Name"
            value={this.props.userName}
            onChange={this.handleChangeName}
          />
        </td>
        <td className="text-center" width="400">
          <input
            type="text"
            className="w-100"
            placeholder="Email"
            value={this.props.userEmail}
            onChange={this.handleChangeEmail}
          />
        </td>
        <td className="text-center" width="400">
          <input
            type="text"
            className="w-100"
            placeholder="Password"
            value={this.props.passwrd}
            onChange={this.handleChangePassword}
          />
        </td>
        <td className="text-center" width="400">
          <input
            type="text"
            className="w-100"
            placeholder="Organisation Id"
            value={this.props.org}
            onChange={this.handleChangeOrganisation}
          />
        </td>
        <td className="text-center" width="400">
          <input
            type="text"
            className="w-100"
            placeholder="Role"
            value={this.props.role}
            onChange={this.handleChangeRole}
          />
        </td>
        <td className="text-center" width="100"></td>
        <td className="text-center" width="10">
          <button className="btn-success" onClick={this.handleSubmit}>
            <i className="fa fa-plus" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    );
  }
}
export class UserRow extends React.Component {
  state = {
    existingUserName: this.props.user.name,
    existingUserEmail: this.props.user.email,
    password: "",
  };

  handleChangeEmail = (e) => {
    this.setState({ existingUserEmail: e.target.value });
  };

  handleChangeName = (e) => {
    this.setState({ existingUserName: e.target.value });
  };

  handleChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  handleResetPasswordEvent = () => {
    userRepository.updatePassword(
      this.props.user.id,
      { password: this.state.password },
      (res) => this.setState({ password: "" }),
      (error) => {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500 &&
          error.response.data
        ) {
          alert(error.response.data.message);
        } else {
          alert("Could not change password, please try again later.");
        }
      }
    );
  };

  render() {
    const user = this.props.user;

    return (
      <tr>
        <td className="text-center">{user.id}</td>
        <td className="text-center">
          <textarea
            className="textarea"
            rows="1"
            cols="20"
            readOnly={this.props.readOnly[this.props.index]}
            defaultValue={this.state.existingUserName}
            onChange={this.handleChangeName}
          />
        </td>
        <td className="text-center">
          <textarea
            className="textarea"
            rows="1"
            cols="20"
            readOnly={this.props.readOnly[this.props.index]}
            defaultValue={this.state.existingUserEmail}
            onChange={this.handleChangeEmail}
          />
        </td>
        <td className="text-center">
          <textarea
            className="textarea"
            rows="1"
            cols="20"
            readOnly={false}
            onChange={this.handleChangePassword}
            value={this.state.password}
          />
          <button
            className="btn btn-primary"
            onClick={() => this.handleResetPasswordEvent()}
          >
            <i className="fa fa-check-circle" aria-hidden="true"></i>
          </button>
        </td>
        <td className="text-center">{user.organisationId}</td>
        <td className="text-center">{user.role}</td>
        <td className="text-center">{user.createdAt}</td>
        <td className="text-center">
          {this.props.renderButton(
            this.props.index,
            parseInt(user.id),
            this.state.existingUserName,
            this.state.existingUserEmail,
            user.organisationId,
            user.role
          )}

          <button
            className="btn btn-danger"
            onClick={() => this.props.handleDelete(parseInt(user.id))}
          >
            <i className="fa fa-close" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    );
  }
}
export default withStyles(styles)(Users);

