import React from 'react';
import Users from './Users.jsx';
import Courses from './Courses.jsx';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import {courseRepository, userRepository, subscriptionRepository} from './repositories/repositories.jsx';
import url from './UrlMapping.jsx';

class UserProfile extends React.Component {

  constructor(props) {
        super(props);
		this.state = {
			userId: props.match.params.id,
			user:[],
			courses:[],
			allcourses:[],
			user:{
				id:props.match.params.id,
				name:'',
				courses:{
					id:props.match.params.id,
					name:'',
					description:'',
					lectures:[]
				}
			},
			courseid: 0
		};
		}
  
  handleChange=(e)=> {
		this.setState({ courseid: e.target.value });
  };
  
  
  handleCourseSubmit=(e)=> {
         e.preventDefault();
         subscriptionRepository.save({userId: (this.state.user.id), courseId:(this.state.courseid)},
          res => {
			 
			  this.setState({courses:res.data});
			  
			},
			(error)=>{}
			)
	 
    };
	componentDidMount(){
			userRepository.getById(this.state.user.id,
			res => {this.setState({user:res.data,courses:res.data.courses});},
			(error)=>{
				this.setState({isError:true});
			})
		  courseRepository.getAll(res => {
			this.setState({ allcourses:res.data,isError: false});
		},(error) => {
			this.setState({ isError: true });
		})
	};
		

   render() {
	if(this.state.user.courses.length==0)
	return(
		<div>
		<h3 align="center"><u>Particular User Information</u></h3>
		UserID:&nbsp;&nbsp;&nbsp;{this.state.user.id}
		<br /><br />

		UserName:&nbsp;&nbsp;&nbsp;{this.state.user.name}
		<br />	
		<h4><u>Enrolled Courses :</u></h4>
		
		<h5>No course subscribed</h5>
		<table className="table table-hover">
		    <tbody>
			    <tr className="bg-warning"> 
				<th className="text-center"><u>CourseId</u> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
				<th className="text-center"><u>CourseName</u> &nbsp;&nbsp;&nbsp;</th>
				<th className="text-center"><u>Description</u></th>
				</tr>
			</tbody>
		</table>
		<br /><br />
	    <hr/>
	 
		Add Courses:&nbsp;&nbsp;&nbsp;
		<select value={this.state.courseid} onChange={this.handleChange} >
		<option>Choose Your Course</option>
		{this.state.allcourses.map((allcourse,i) =><option key={'allcourse_' + i} value={allcourse.id}>{allcourse.name}</option>)}
		</select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		<button type="submit" onClick={this.handleCourseSubmit}>AddCourse</button>
		</div>
	);
    return (
	  
	  <div>
		
		<h3 align="center"><u>Particular User Information</u></h3>
		UserID:&nbsp;&nbsp;&nbsp;{this.state.user.id}
		<br /><br />

		UserName:&nbsp;&nbsp;&nbsp;{this.state.user.name}
		<br />
		<h4><u>Enrolled Courses :</u></h4>
		
		<table className="table table-hover">
		    <tbody>
			    <tr className="bg-warning"> 
				<th className="text-center"><u>CourseId</u> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
				<th className="text-center"><u>CourseName</u> &nbsp;&nbsp;&nbsp;</th>
				<th className="text-center"><u>Description</u></th>
				</tr>
				{this.state.courses.map((course,i) => <tr key={'course_' + i}>
						<td className="text-center">{course.id}</td>
						<td className="text-center">{course.name}</td>
						<td className="text-center">{course.description}</td>			
						</tr>)}
		    </tbody>
				  
	    </table>
		
		<br /><br />
	    <hr/>

		Add Courses:&nbsp;&nbsp;&nbsp;
		<select value={this.state.courseid} onChange={this.handleChange} >
		<option>Choose Your Course</option>
		{this.state.allcourses.map((allcourse,i) =><option key={'allcourse_' + i} value={allcourse.id}>{allcourse.name}</option>)}
		</select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		<button type="submit" onClick={this.handleCourseSubmit}>AddCourse</button>

	  </div>
	  );
   }
}

export default UserProfile;
