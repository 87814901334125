import api from "../api.jsx";

class Repository {
  constructor(baseUrl, path) {
    this.path = path;
    this.api = api;
    this.baseUrl = baseUrl;
  }

  getAll = (onSuccess, onFailure) =>
    api
      .get(this.endpoint())
      .then((response) => onSuccess(response))
      .catch((error) => onFailure(error));

  save = (entity, onSuccess, onFailure) =>
    api
      .post(this.endpoint(), entity)
      .then((response) => onSuccess(response))
      .catch((error) => onFailure(error));

  delete = (id, onSuccess, onFailure) =>
    api
      .delete(this.endpoint() + id)
      .then((response) => onSuccess(response))
      .catch((error) => onFailure(error));

  getById = (id, onSuccess, onFailure) =>
    api
      .get(this.endpoint() + id)
      .then((response) => onSuccess(response))
      .catch((error) => onFailure(error));

  update = (entity, onSuccess, onFailure) =>
    api
      .put(this.endpoint() + entity.id, entity)
      .then((response) => onSuccess(response))
      .catch((error) => onFailure(error));

  updateuserapi = (relativePath, entity, onSuccess, onFailure) =>
    api
      .put(this.endpoint() + relativePath + entity.id, entity)
      .then((response) => onSuccess(response))
      .catch((error) => onFailure(error));

  put = (relativePath, entity, onSuccess, onFailure) =>
    api
      .put(this.endpoint() + relativePath, entity)
      .then((response) => onSuccess(response))
      .catch((error) => onFailure(error));

  post = (relativePath, entity, onSuccess, onFailure) =>
    api
      .post(this.endpoint() + relativePath, entity)
      .then((response) => onSuccess(response))
      .catch((error) => onFailure(error));

  get = (relativePath, onSuccess, onFailure) =>
    api
      .get(this.endpoint() + relativePath)
      .then((response) => onSuccess(response))
      .catch((error) => onFailure(error));

  endpoint = () => {
    return this.baseUrl + this.path;
  };
}

export default Repository;
