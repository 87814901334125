import { NavigateBeforeSharp } from '@material-ui/icons';
import React from 'react';

class TableHeaderRow extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <thead>
      <tr className="bg-warning">
      {this.props.titles.map((title,i) => 
        <th key={'table_header'+i} className='text-center'><u><h4>{title}</h4></u></th>
      )}
    </tr>
    </thead>
    );
  }
}

export default TableHeaderRow;