import React from 'react';
import { withStyles } from "@material-ui/core";
import { subscriptionRepository ,organisationRepository } from './repositories/repositories.jsx';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import {formatDate} from './formatDate.js'
const styles = (theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  });
class SubscriptionFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      emails: "",
      organisations:[],
      startdate: new Date(2018,1,1).toISOString().substring(0, 10),
      enddate: new Date().toISOString().substring(0, 10),
      orgId: "",
      orgfilter: false,
      datefilter: false,
      isError:false

    }
  }

  handleChangeOrganisation = (org) => this.setState({ org: org });
  handlestartdateChange = (e) => this.setState({ startdate: e.target.value });
  handleenddateChange = (e) => this.setState({ enddate: e.target.value });
  handleorgIdChange = (e) => {
    this.state.organisations.filter(org => org.name === e.target.value).map(org => {this.setState({orgId:org.id})})
  }

  resetOrgState = () =>{
    this.setState({orgId:""});
}
resetDateState = () =>{
  this.setState({startdate:new Date(2018,1,1).toISOString().substring(0, 10), enddate: new Date().toISOString().substring(0, 10)})
}
  handleOrgFilterArrowClick = () =>
  this.setState({ orgfilter: !this.state.orgfilter });
handleDateFilterArrowClick = () =>
  this.setState({ datefilter: !this.state.datefilter });
  componentDidMount() {
    subscriptionRepository.fetchAllUsers(
      formatDate(this.state.startdate),
      formatDate(this.state.enddate),
        this.state.orgId,
        (response) => {
          this.setState({ isError: false, users: response.data });
        },
        (error) => console.log("Failed to load users..")
      );
      organisationRepository.getAll(res => {this.setState({ organisations: res.data })},
      (error) => {
        console.log("Failed to load users..")
      })
  }
  componentDidUpdate(prevProps,prevState) {
    if (
      prevState.startdate !== this.state.startdate ||
      prevState.enddate !== this.state.enddate ||
      prevState.orgId != this.state.orgId
    ) {
        
      subscriptionRepository.fetchAllUsers(
        formatDate(this.state.startdate),
        formatDate(this.state.enddate),
        this.state.orgId,
        (response) => {
          this.setState({ isError: false, users: response.data });
        },
        (error) => console.log("Failed to load users..")
      );
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
         
        <table className="table table-hover">
        <thead>
              <tr className="bg-warning">
                <th className="text-center">
                  <u>
                    <h4>Subscription Id</h4>
                  </u>
                </th>
                <th className="text-center">
                  <u>
                    <h4>User Name</h4>
                  </u>
                </th>
                <th className="text-center">
                  <u>
                    <h4>Email</h4>
                  </u>
                </th>
                <th className="text-center">
                  <u>
                    <h4>Course</h4>
                  </u>
                </th>
                <th className="text-center">
                  <u>
                    <h4>
                      <div>
                      Org Id
                        <ArrowDropDownIcon
                          aria-describedby="orgFilter"
                          variant="contained"
                          onClick={this.handleOrgFilterArrowClick}
                        />
                        <Popover
                          open={this.state.orgfilter}
                          anchorReference="anchorPosition"
                          anchorPosition={{ top: 80, left: 840 }}
                          id="orgFilter"
                          onClose={this.handleOrgFilterArrowClick}
                        >
                          <Typography className={classes.typography}>
                            <input
                              type="text"
                              onChange={this.handleorgIdChange}
                            />
                            <br/>
                            {
                              this.state.organisations.map(
                                org =>
                               <div>
                                <input type="radio" id={org.name} value={org.name} onClick={this.handleorgIdChange} checked={this.state.orgId==org.id}/>&nbsp;&nbsp;
                                <label for={org.name}>{org.name}</label><br/>
                                </div>
                              )
                            }
                            <br />
                            <center><button onClick={this.resetOrgState}>Reset</button></center>
                          </Typography>
                        </Popover>
                      </div>
                    </h4>
                  </u>
                </th>
                <th className="text-center">
                  <u>
                    <h4>
                      subscriptionDate
                      <ArrowDropDownIcon
                        aria-describedby="dateFilter"
                        variant="contained"
                        onClick={this.handleDateFilterArrowClick}
                      />
                      <Popover
                        open={this.state.datefilter}
                        id="dateFilter"
                        anchorReference="anchorPosition"
                          anchorPosition={{ top: 80, left: 1000 }}
                        onClose={this.handleDateFilterArrowClick}
                      >
                        <Typography className={classes.typography}>
                          Start Date:
                          <input
                            type="date"
                            onChange={this.handlestartdateChange}
                            value={this.state.startdate}
                          />{" "}<br /><br />
                          End Date  :{" "}
                          <input
                            type="date"
                            onChange={this.handleenddateChange}
                            value={this.state.enddate}
                          />
                          <br /><br/>
                          <center><button onClick={this.resetDateState}>Reset</button></center>
                        </Typography>
                      </Popover>
                    </h4>
                  </u>
                </th>
              </tr>
            </thead>
          <tbody>
            {this.state.users.map((user, index) => <UserDataRow key={'users_' + index} index={index}
              user={user} />)}
          </tbody>
        </table>
        
      </div>
    );
  }
}
class UserDataRow extends React.Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      return (<tr key={'userrow_' + this.props.index} >
        <td className="text-center">{this.props.user.id}</td>
        <td className="text-center">{this.props.user.name}</td>
        <td className="text-center">{this.props.user.email}</td>
        <td className="text-center">{this.props.user.course}</td>
        <td className="text-center">{this.props.user.orgId}</td>
        <td className="text-center">{this.props.user.subscriptionDate}</td>
      </tr>);
    }
  }
  export default withStyles(styles)(SubscriptionFilter);