import React from 'react';
import { lessonRepository, lectureRepository } from './repositories/repositories.jsx';
import {withStyles} from '@material-ui/core/styles'
import LectureCard from './LectureCard.jsx'

const style = theme => ({
  root : {
    backgroundColor:'#F5F5F5',
    fontWeight : '500',
    padding:'0 0 2em 0'
  },
  managecourse : {
    backgroundColor :'#5DAA68',
    padding : '2%',
    marginBottom: '2%',
  },
  lectureContainer:{
    display:'flex',
    width:'100%',
    justifyContent:'center'
  },
  lectureId:{
    width:'10%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    fontSize:'40px',
    color:'#888',
  }
  })


class LessonDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lessonId: props.match.params.id,
      lesson: {
        id: props.match.params.id,
        name: '',
        description: '',
        lectures: []
      },
      focused : undefined
    };
  }

  componentDidMount() {
    this.get();
  }

  handleShiftUp = (lectureId) => {
    lessonRepository.shiftLectureUp(this.state.lessonId, lectureId,
      response => {
        this.get()
        this.setState({focused: lectureId})
      },
      error => alert("Could not move lecture. Please try again later.")
    )
  };

  handleDelete = (i) => {
    confirm("Do you want to delete this lecture?") && lectureRepository.delete(i,
      response => this.get(),
      (error) => {
        if (error.response && error.response.status === 409) {
          alert(error.response.data)
        } else {
          alert("Could not delete lecture, please try again later.");
        }
      })
  }


  handleUpdatedName = (e) => {
    var lesson = this.state.lesson;
    lesson.name = e.target.value;
    this.setState({ lesson });
  };

  handleUpdatedDescription = (e) => {
    var lesson = this.state.lesson;
    lesson.description = e.target.value;
    this.setState({ lesson });
  };

  get = () => {
    lessonRepository.getById(this.state.lesson.id,
      res => this.setState({ lesson: res.data }),
      (error) => alert(error.response.data))
  };

  handleSave = () => {
    lessonRepository.update(this.state.lesson,
      res => this.setState({ lesson: res.data }),
      error => alert("Could not Edit lessons, please try again later.")
    )
  };


  render() {
    const {classes} = this.props;
    if (this.state.isError) {
      return (
        <div>
          <h3 className="text-center">Could not fetch lectures, please try again later.</h3>
        </div>);
    } else {
      return (
        <div className={classes.root}>
          <div className={classes.managecourse}>
            <h3 align="center"><u>Manage Lesson</u></h3>
            <table align="center">
              <tbody>
                <tr><td>Lesson Id:</td><td>{this.state.lesson.id}</td></tr>
                <tr><td>Lesson Name:</td><td><textarea className="textarea" rows="1" cols="30" value={this.state.lesson.name} onChange={this.handleUpdatedName} /></td></tr>
                <tr><td>Lesson Description:</td><td><textarea className="textarea" rows="1" cols="30" value={this.state.lesson.description} onChange={this.handleUpdatedDescription} /></td></tr>

                <tr><td></td><td><button className="btn btn-info" onClick={() => this.handleSave()}>Save</button></td></tr>
              </tbody>
            </table>
          </div>
          {this.state.lesson.lectures.map((lecture,i) => 
            <div key={i} className={classes.lectureContainer}>
              <div className={classes.lectureId}>{lecture.id}</div>
              <LectureCard  
                lecture={lecture}
                handleShiftUp = {this.handleShiftUp}
                handleDelete = {this.handleDelete}
                addCard={false}
                focused={this.state.focused==lecture.id}
                getLesson={this.get}
                lessonId={this.state.lessonId}
                />
            </div>
            )}

            <div className={classes.lectureContainer}>
              <div className={classes.lectureId}>Add</div>
              <LectureCard 
                lecture={{}}
                handleShiftUp = {() => {}}
                handleDelete = {() => {}}
                addCard={true}
                getLesson={this.get}
                lessonId={this.state.lessonId}
              />
            </div>
        </div>
      );
    }
  }

}

export default withStyles(style)(LessonDetail);