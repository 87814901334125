import React from 'react';
import 'react-tabs/style/react-tabs.css';
import AdminHeader from './components/AdminHeader.jsx'
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      user: {
        id: 0,
        name: '',
        email: '',
        organisationId: 0,
        courses: []
      }
    };



  }

  componentDidMount() {

  }


  render() {
    let error, csm;
    if (!this.state.isError) {
      error = ""
    }
    else {
      error = <h5 style={{ color: "red" }}>There was an error creating the user. Please check the data and try again.!!</h5>
    }

    return (
      <div>
        <AdminHeader />
        <div className="text-center">
          Welcome Admin
	      </div>
      </div>
    );
  }
}
// exporting the App component to render it from main.js
export default App;