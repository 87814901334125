import api from '../api.jsx';
import url from '../UrlMapping.jsx';
class LoginRepository
{
    logout = (onSuccess,onFailure)=>{
		api.get(url +"/logout")
		.then(response=>onSuccess(response))
		.catch(error=>onFailure(error));
	};
	
}
var loginRepository = new LoginRepository();

export default loginRepository;