import React, { Component } from 'react';
import { withStyles, Button } from '@material-ui/core'
import {adminRepository} from './repositories/repositories.jsx'

const styles = theme => ({
    container: {
        display: 'flex',
        height:'100vh',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    subject:{
        marginBottom:'5em'
    },
    submit:{
        marginTop:'1em',
    }
})

class Email extends Component {
    state = {
        subject: '',
        body: ''
    };

    handleChange = e => {
        const {name, value} = e.target
        this.setState({[name]: value}) 
    }

    handleSubmit = () => {
        adminRepository.post("email", this.state, () => alert("Email sent successfully"), () => alert("Email failed to send"))
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                <div className={classes.subject}>
                    <b><pre>Subject:</pre></b> <textarea name="subject" rows="4" cols="80" value={this.state.subject} onChange={this.handleChange}/>
                </div>
                <div>
                    <b><pre>Body : </pre></b><textarea name="body" rows="15" cols="80" value={this.state.body} onChange={this.handleChange}/>
                </div>
                <Button onClick={this.handleSubmit} variant="contained" className={classes.submit} color="primary">Send</Button>
            </div>
        );
    }
}

export default withStyles(styles)(Email);