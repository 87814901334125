import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));

  export default function Dropdown({value, setValue, items}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    function handleChange(event) {
        setValue(event.target.value);
      }
    
    function handleClose() {
        setOpen(false);
    }
    
    function handleOpen() {
      setOpen(true);
    }
    
    return (
        <FormControl className={classes.formControl}>
        <InputLabel htmlFor="value">{value}</InputLabel>
        <Select
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={value}
            onChange={handleChange}
            inputProps={{
            name: 'value',
            id: 'value',
         }}
        >
        {
          items.map(
            (dropdownItem, i) => 
             <MenuItem key={'value_'+i+dropdownItem} value={dropdownItem}>{dropdownItem}</MenuItem>
          )
        }
        </Select>
        </FormControl>
        );
    }
