import React, { useEffect, useRef } from 'react';
import {withStyles} from '@material-ui/core/styles'
import Close from '@material-ui/icons/Clear'
import { Checkbox, Button } from '@material-ui/core';
import { green } from '@material-ui/core/colors'

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);

const styles = theme => ({
    container : {
        display : "flex",
        justifyContent : "space-between",
    },
    textarea : {
        backgroundColor: 'transparent',
        border : 'none',
        color: '#555',
        boxSizing: 'border-box',
        borderBottom: `1px solid #888`,
        fontSize: '18px',
        left: '0%',
        padding: '0px 0px',
        position: 'relative',
        resize: 'none',
        hieght:'16px',
        width : '40vw',
        overflow : 'hidden',
        marginRight : '1em',
        '&:focus': {
          outline: 'none',
          borderBottom: `2px solid #666`,
          color : '#111',    
        },
      },
})

function Option (props) {
    const [visibility, setVisibility] = React.useState(true)
    const {classes} = props
    let myRef = useRef()

    useEffect(() => {
        props.resizeTextarea(myRef.current)
    },[props.option])

    return (
        visibility &&
        <div className={classes.container}>
            <GreenCheckbox 
                checked={props.correct}
                onChange={() => props.handleChangeCheckbox(props.index)}
            />
            <textarea className={classes.textarea} placeholder={"option"} ref={myRef} value={props.option} rows="1" onChange={e => props.handleChangeOptions(e, props.index)}/>
            <Button onClick={() => {
                setVisibility(false)
                props.handleDeleteOptions(props.index)
            }}><Close /></Button>
        </div>
    );
    
}

export default withStyles(styles)(Option);