import Repository from './repository.jsx';
import url from '../UrlMapping.jsx';

class UserRepository extends Repository {
  constructor() { super(url, "/users/"); }
  getCurrentUser = (onSuccess, onFailure) => this.get("me", onSuccess, onFailure);
  currentUserCourses = (onSuccess, onFailure) => this.get("me/courses", onSuccess, onFailure)
  updateuser = (entity,onSuccess, onFailure) =>  this.updateuserapi("me/",entity, onSuccess, onFailure);
  updatePassword = (id, entity, onSuccess, onFailure) =>  this.put(id + "/password", entity, onSuccess, onFailure);
  deleteUser = (id,onSuccess,onFailure) => this.delete(id,onSuccess,onFailure);
  allUsers = (startdate,enddate,orgId,onSuccess,onFailure) => this.get("?startdate="+startdate+"&enddate="+enddate+"&id="+orgId,onSuccess,onFailure)
}

class CourseRepository extends Repository {
  constructor() { super(url, "/courses/"); }
  addLesson = (id, lesson, onSuccess, onFailure) => this.post(id + "/lessons", lesson, onSuccess, onFailure)
  getByIdOld = (id, onSuccess, onFailure) => this.get(id + "/old", onSuccess, onFailure);
  shiftLessonUp = (id, lessonId, onSuccess, onFailure) => {
    this.put(id + "/lessons/"+lessonId+"/moveup", null, onSuccess, onFailure);
  }
}

class SubscriptionRepository extends Repository {
  constructor() { super(url, "/subscriptions/"); }
  subscribeAllByEmail = (id, emails, onSuccess, onFailure) =>
    this.post("subscribebyemail", { emails, courseId: id}, onSuccess, onFailure)
  unsubscribeUserFromCourse = (id, userId, onSuccess, onFailure) => 
    this.put("/unsubscribe", {userId, courseId:id}, onSuccess, onFailure)
    allUsersFor = (id, onSuccess, onFailure) => this.get("bycourseid/"+id + "/users", onSuccess, onFailure)
    fetchAllUsers = (startdate,enddate,orgId,onSuccess,onFailure) => this.get("?startdate="+startdate+"&enddate="+enddate+"&id="+orgId,onSuccess,onFailure)
  }

class LessonRepository extends Repository {
  constructor() {super(url, "/lessons/");}
  shiftLectureUp = (id, lectureId, onSuccess, onFailure) => {
    this.put(id + "/lectures/"+lectureId+"/moveup", null, onSuccess, onFailure);
  }
}

class InvoiceRepository extends Repository{
  constructor() {super(url, "/invoices/");}
  fetchAllInvoices = (startdate,enddate,orgId,onSuccess,onFailure) => this.get("?startdate="+startdate+"&enddate="+enddate+"&id="+orgId,onSuccess,onFailure);
}


export var lectureRepository = new Repository(url, "/lectures/");
export var userRepository = new UserRepository();
export var courseRepository = new CourseRepository();
export var subscriptionRepository = new SubscriptionRepository();
export var organisationRepository = new Repository(url, "/organisations/");
export var adminRepository = new Repository(url, "/admin/");
export var lessonRepository = new LessonRepository();
export var invoiceRepository = new InvoiceRepository();