import React from 'react';
import {courseRepository} from './repositories/repositories.jsx';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import TableHeaderRow from './components/table.component.jsx';
import LinkButton from './components/LinkButton.jsx';


var mode = [], textarea = [], inputEdit = [], success = [], match;
class Courses extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			courses: [],
			courseDescription: '',
			courseName: '',
			price: '',
			success: [],
			isError: false
		};
	}


	componentDidMount() {
    this.fetchAll();
  };
  
  fetchAll = () => {
		courseRepository.getAll(res => {
			this.setState({ isError: false });
			const courses = res.data;
			let len = courses.length;
			for (let i = 0; i < len; i++) {
				mode[i] = 'view';
				textarea[i] = 'readOnly';
				inputEdit[i] = 'readOnly';
				success[i] = '';
			}
			this.setState({ courses, mode, textarea, inputEdit, success });
		},(error) => {
			this.setState({ isError: true });
		})
  }

	handleChangeName =(courseName) =>{
		this.setState({ courseName:courseName });
	}

	handleChangeDescription =(courseDescription) =>{		
		this.setState({ courseDescription:courseDescription });
	}

	handleChangePrice =(price) =>{		
		this.setState({ price : price });
	}

	handleSubmit = (e) => {
	//to prevent default action happen
	
	e.preventDefault();
	courseRepository.save({ name: this.state.courseName, description: this.state.courseDescription,
														price: this.state.price
												},
    res =>{  
      this.setState({ isError: false, courseName:"", courseDescription:"", price:"" });
      this.fetchAll();
    },
		(error) => {
			if (error.response.status === 409 || error.response.status === 400) {
				alert(error.response.data.message)
			} else {
				alert("Could not add course, please try again later.");
			}
		}
		)
	
	};

	handleDelete = (i) => {
	if(confirm("Are you sure you want to delete course?"))		
		courseRepository.delete(i,
			res => {
		this.setState({isError:false})
		this.fetchAll();
		},
			(error) => {
				if (error.response && error.response.status === 409) {
					alert(error.response.data)
					
				}
				else {
					alert("Could not delete course, please try again later.");
				}
			})	
	};

	render() {
		if (this.state.isError) {
			return (
				<div>
				<h3 className="text-center">Could not fetch courses, please try again later.</h3>
				</div>);
		}

		else {
			return (

				<div className="Courses">
				<table className="table table-hover">
        <TableHeaderRow titles={["Course Id", "Course Name", "Course Description", "Price", "Action"]}/>
			<tbody>
			
			{this.state.courses.map(
			(course, i) =><CourseRow key={'course_' + i}
			handleDelete={this.handleDelete} 
			course={course}
			url={this.props.match.url}
			index={i}/>)}
			
			
			<AddCourseRow
		
			courseName={this.state.courseName}
			courseDescription={this.state.courseDescription}
			price={this.state.price}
			handleSubmit={this.handleSubmit}
			handleChangeName={this.handleChangeName}
			handleChangeDescription={this.handleChangeDescription}
			handleChangePrice={this.handleChangePrice}
			/>
			
			</tbody>
			</table>
			</div>
			);
		}
	}
}

class CourseRow extends React.Component{
	constructor(props) {
		super(props);  
	}
	render(){
		const course= this.props.course;
		const url=this.props.url;
		return (<tr key={'course_' + this.props.index}>
			<td className="text-center">{course.id}</td>
			<td className="text-center">
			<Link to={{ pathname:`courses/${course.id}`,courseDetails: { courseID: course.id, courseName: course.name,courseDescription: course.description}} }>			
			{course.name}
			</Link>
			</td>
			<td className="text-center">
			{course.description}
			</td>
			<td className="text-center">
			{course.price}
			</td>
			<td className="text-center">
      <LinkButton className="btn btn-info fa fa-user-o" to={`/courses/${course.id}/users`}/>
			<button className="btn btn-danger fa fa-close"  onClick={()=>this.props.handleDelete(parseInt(course.id))}>
			</button>
			</td>
			</tr>);
	}
}

class AddCourseRow extends React.Component {
	constructor(props) {
	super(props); 
	this.handleChangeName = this.handleChangeName.bind(this);
	this.handleChangeDescription= this.handleChangeDescription.bind(this);
	this.handleChangePrice= this.handleChangePrice.bind(this);
	this.handleSubmit=this.handleSubmit.bind(this);
	}
	handleChangeName(e){this.props.handleChangeName(e.target.value);}
	handleChangeDescription(e){this.props.handleChangeDescription(e.target.value);}
	handleChangePrice(e){this.props.handleChangePrice(e.target.value);}
	handleSubmit(e){this.props.handleSubmit(e);}

	render() {
    return (
      <tr>
		<td></td>
        <td className="text-center"><input type="text" placeholder="Course Name" value={this.props.courseName} onChange={this.handleChangeName} /></td>
		<td className="text-center"><input type="text" placeholder="Course Description" value={this.props.courseDescription} onChange={this.handleChangeDescription} /></td>
		<td className="text-center"><input type="text" placeholder="Price" value={this.props.price} onChange={this.handleChangePrice} /></td>
		<td className="text-center">
		<button className="btn-success" onClick={this.handleSubmit}>
		<i className="fa fa-plus" aria-hidden="true"></i>
		</button>
		</td>
		</tr>
    );
  }
}
export default Courses;