import React from 'react';
import { courseRepository, lessonRepository } from './repositories/repositories.jsx';
import TableHeaderRow from './components/table.component.jsx';
import {Link} from 'react-router-dom';

class ManageCourse extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      courseId: props.match.params.id,
      readOnlyMode: true,
      lessonName: '',
      lessonDescription: '',
      course: {
        id: props.match.params.id,
        name: '',
        description: '',
        price:'',
        lessons: []
      }
    };
  }

  componentDidMount() {
    this.get();
  }

  handleLessonName = (lessonName) => this.setState({ lessonName: lessonName });
  handleLessonDescription = (lessonDescription) => this.setState({ lessonDescription });

  handleUpdatedName = (e) => {
    var course = this.state.course;
    course.name = e.target.value;
    this.setState({ course });
  };

  handleUpdatedDescription = (e) => {
    var course = this.state.course;
    course.description = e.target.value;
    this.setState({ course });
  };

  handleUpdatedPrice = (e) => {
    var course = this.state.course;
    course.price = e.target.value;
    this.setState({ course });
  };

  get = () => {
    courseRepository.getById(this.state.course.id,
      res => {this.setState({ course: res.data, readOnlyMode: true })},
      (error) => alert(error.response.data))
  };

  handleSave = () => {
    this.setState({ loading: true, readOnlyMode: true });
    courseRepository.update(this.state.course,
      res => {
        this.setState({ course: res.data });
        this.handleLessonName("");
        this.handleLessonDescription("");
      }, (error) => {
        if (error.response.status === 409 || error.response.status === 400) {
          alert(error.response.data.message)
        } else {
          alert("Could not Edit courses, please try again later.");
        }
      })
  };

  handleSubmit = (e) => {
    //to prevent default action happen
    e.preventDefault();
    courseRepository.addLesson(this.state.courseId,
      { name: this.state.lessonName, description: this.state.lessonDescription },
      res => {
        this.setState({ lessonName: '', lessonDescription: '' });
        this.get();
      }, (error) => {
        if (error.response && error.response.status === 409 || error.response.status === 400) {
          alert(error.response.data.message)
        } else {
          alert("Could not add lesson, please try again later.");
        }
      })
  };

  handleShiftUp = (lessonId) => {
    courseRepository.shiftLessonUp(this.state.courseId, lessonId,
      response => {
        var course = response.data;
        this.setState({ course: course, isError: false });
      }, (error) => {
        alert("Could not reorder lesson, please try again later.");
      })

  };

  handleEdit = () => this.setState({ readOnlyMode: false });

  handleDelete = (i) => {
    if(confirm("Are you sure you want to delete lesson?"))
    lessonRepository.delete(i,
      res => {
        var course = this.state.course;
        course.lessons = course.lessons.filter(lesson => lesson.id !== i);
        this.setState({ course: course, isError: false });
      }, (error) => {
        if (error.response && error.response.status === 409) {
          alert(error.response.data)
        } else {
          alert("Could not delete lesson, please try again later.");
        }
      })
  }

  onSaveButtonClick = () => {
    if (this.state.readOnlyMode) this.handleEdit(); else this.handleSave();
  }

  render() {
    if (this.state.isError) {
      return (
        <div>
          <h3 className="text-center">Could not fetch lessons, please try again later.</h3>
        </div>);
    } else {
      return (
        <div>
          <h3 align="center"><u>Manage Course</u></h3>
          <table align="center">
            <tbody>
              <tr><td>Course Id:</td><td>{this.state.courseId}</td></tr>
              <tr><td>Course Name:</td><td><textarea className="textarea" rows="1" cols="30" readOnly={this.state.readOnlyMode} value={this.state.course.name} onChange={this.handleUpdatedName} /></td></tr>
              <tr><td>Course Description:</td><td><textarea className="textarea" rows="1" cols="30" readOnly={this.state.readOnlyMode} value={this.state.course.description} onChange={this.handleUpdatedDescription} /></td></tr>
              <tr><td>Course Price:</td><td><textarea className="textarea" rows="1" cols="30" readOnly={this.state.readOnlyMode} value={this.state.course.price} onChange={this.handleUpdatedPrice} /></td></tr>

              <tr><td></td><td><button className="btn btn-info" onClick={() => this.onSaveButtonClick()}>{this.state.readOnlyMode ? "Edit" : "Save"}</button></td></tr>
            </tbody>
          </table>
          <table className="table table-hover">
            <TableHeaderRow titles={["Lesson id","Lesson Name", "Lesson Description", "Action", "Reorder"]} />
            <tbody>

              {this.state.course.lessons.map(
                (lesson, i) => <LessonRow key={'lessonrow_' + i}
                  handleDelete={this.handleDelete}
                  handleShiftUp={this.handleShiftUp}
                  lesson={lesson}
                  index={i} />)}

              <AddLessonRow
                lessonName={this.state.lessonName}
                lessonDescription={this.state.lessonDescription}
                handleSubmit={this.handleSubmit}
                handleLessonName={this.handleLessonName}
                handleLessonDescription={this.handleLessonDescription} />

            </tbody>
          </table>
        </div>
      );
    }
  }

}

class LessonRow extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const lesson = this.props.lesson;
    return (<tr key={'lesson_' + this.props.index}>
      <td>{lesson.id}</td>
      <td>
      <Link to={{pathname : `/lessons/${lesson.id}`}}>
      {lesson.name}
      </Link>
      </td>
      <td className="text-center">
        {lesson.description}
      </td>
      <td className="text-center">
        <button className="btn btn-danger" onClick={() => this.props.handleDelete(parseInt(lesson.id))}>
          <i className="fa fa-close" aria-hidden="true"></i>
        </button>
      </td>
      <td className="text-center" onClick={() => this.props.handleShiftUp(lesson.id)}>
        <button className="btn btn-primary">
          <i className="fa fa-arrow-up" aria-hidden="true" ></i>
        </button>
      </td>
    </tr>);
  }
}
class AddLessonRow extends React.Component {
  constructor(props) {
    super(props);
    this.handleLessonName = this.handleLessonName.bind(this);
    this.handleLessonDescription = this.handleLessonDescription.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleLessonName(e) { this.props.handleLessonName(e.target.value); }
  handleLessonDescription(e) { this.props.handleLessonDescription(e.target.value); }
  handleSubmit(e) { this.props.handleSubmit(e); }
  render() {
    return (
      <tr>
        <td className="text-center"><input type="text" placeholder="Lesson Name" value={this.props.lessonName} onChange={this.handleLessonName} /></td>
        <td className="text-center"><input type="text" placeholder="Lesson Description" value={this.props.lessonDescription} onChange={this.handleLessonDescription} /></td>
        <td className="text-center">
          <button className="btn-success" onClick={this.handleSubmit}>
            <i className="fa fa-plus" aria-hidden="true"></i>
          </button>
        </td>
        <td></td>
      </tr>
    );
  }
}

export default ManageCourse;